<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/images/yuwan/registrationHead.jpg" />
    </div>
    <div class="content">
      <div class="titleLogin">全国总决赛奖金信息登记</div>
      <el-form
        :model="formInline"
        ref="formInline"
        class="demo-form-inline"
        label-width="111px"
      >
        <el-row :gutter="24" style="margin-left: 0px; margin-right: 0px">
          <el-col :span="20" class="numberContent">
            <el-input
              type="number"
              v-model="formInline.phone"
              @blur="validatePhoneNumber"
              placeholder="输入手机号"
            >
            </el-input>
            <el-button
              class="sendBtn"
              @click="sendCode"
              :disabled="isSending || countdown > 0"
            >
              {{
                isSending
                  ? "发送中..."
                  : countdown > 0
                  ? `获取验证码${countdown}`
                  : title
              }}
            </el-button>
          </el-col>
          <el-col :span="20">
            <el-input
              v-model="formInline.code"
              placeholder="输入验证码"
              type="text"
            />
          </el-col>
          <el-col :span="18">
            <el-button class="inquireBtn" type="primary" @click="enery"
              >确认</el-button
            >
          </el-col>
          <div class="descTitle">
            <p class="matter">&nbsp;&nbsp;提示信息：</p>
            <p>
              奖金发放登记截止时间为11月24日24：00，请获奖队伍尽快完成登记。
            </p>
            <p>
              1、一组队伍只允许填写一次收款信息，以先填写的信息为准。（赛道二由队长填写）
            </p>
            <p>2、请仔细核对并如实填写收款信息，一经填写无法修改。</p>
            <p>
              3、登记信息时请填写身份证号，由竞赛方代缴个人偶然所得税（20%），奖金以实收金额为准。
            </p>
            <p>
              4、奖金发放周期为登记截止后15个工作日内。发放完成后由参赛队伍自行分配。
            </p>
            <p>
              5、如更换手机号无法验证，请优先使用其他队员手机进行验证。仍无法验证的请使用竞赛报名登记的邮箱发送邮件至dtcupzuwei@126.com，提供与报名信息一致的收款信息。
            </p>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
 
<script>
import { ElMessage } from "element-plus";
import { competition } from "api/prize.js";

export default {
  data() {
    return {
      formInline: {
        phone: "",
        code: "",
      },
      phone: "",
      isSending: false,
      countdown: 0,
      phoneRegex: /^1[3-9]\d{9}$/,
      phoneError: false,
      title: "获取验证码",
    };
  },
  methods: {
    validatePhoneNumber() {
      this.phoneError = this.phoneRegex.test(this.formInline.phone);
    },
    sendCode() {
      if (!this.phoneError) {
        ElMessage({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }
      if (this.countdown > 0 || this.isSending) {
        return;
      }
      this.isSending = true;
      this.title = "重新获取验证码";
      this.startCountdown();
      let dataobj = new FormData();
      dataobj.append("phone", this.formInline.phone);
      competition.sendVerification(dataobj).then((res) => {
        if (res.code === 0) {
          this.countdown = 0;
          this.isSending = false;
          ElMessage({
            message: res.msg,
            type: "error",
          });
        } else {
          this.formInline.code = res.sms_verification_code;
        }
      });
      setTimeout(() => {
        this.isSending = false;
      }, 500);
    },
    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    enery() {
      let dataobj = new FormData();
      dataobj.append("phone", this.formInline.phone);
      dataobj.append("code", this.formInline.code);
      if (this.formInline.phone && this.formInline.code) {
        competition.contrastVerification(dataobj).then((res) => {
          if (res.code === 200) {
            this.getInfo();
          } else {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        ElMessage({
          message: "请输入正确的手机号和验证码",
          type: "warning",
        });
      }
    },

    getInfo() {
      let dataobj = new FormData();
      // dataobj.append("phone", "17353829620");
      dataobj.append("phone", this.formInline.phone);
      competition.info(dataobj).then((res) => {
        if (res.code === 200) {
          let list = res.data || [];
          if (list.length == 0) {
            ElMessage({
              message: "该账号暂无信息，请联系组委会",
              type: "error",
            });
            return;
          }
          let idList = list.join(",");
          localStorage.setItem("phone", this.formInline.phone);
          localStorage.setItem("idList", idList);
          this.$router.push({
            path: "/InformationCompetition",
            query: { phone: this.formInline.phone, idList },
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./login.scss";
</style>